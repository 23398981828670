import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { ReportService } from 'src/app/shared/services/report.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 
import { finalize } from 'rxjs/operators';
import {EmailService} from 'src/app/shared/services/email.service';
import {HttpClient,HttpHeaders} from '@angular/common/http';


@Component({
	selector: 'app-dashboard-update-report',
	templateUrl: './dashboard-update-report.component.html',
	styleUrls: ['./dashboard-update-report.component.scss']
})
export class DashboardUpdateReportComponent implements OnInit {
	id:string;
	report;
	published;
	uploadPercent;
	downloadURL;
	submitted=false;
	freportUpdate:FormGroup;
	title;
	mapData;
	countCat;
	globalMainCat=new Array();
	constructor(
		public route:Router,
		private rout:ActivatedRoute,
		private reportService:ReportService,
		private fb: FormBuilder,private myStorage: AngularFireStorage,
		private uploadImageService:UploadImageService,
		private emailService:EmailService, private http: HttpClient
		) {
	}

	ngOnInit(){
		this.createForm();
		this.id = this.rout.snapshot.paramMap.get('id');
		this.report=this.reportService.reportGetData(this.id).valueChanges().subscribe(a=>{
			this.freportUpdate.patchValue({
				title:a.title,
				date:a.date,
				time:a.time,
				description:a.description,
				reportDate:a.reportDate,
				youAre:a.youAre,
				happened:a.happened,
				placeType:a.placeType,
				reportPolice:a.reportPolice,
				policeAttitude:a.policeAttitude,
				published:a.published,
				place_location:a.place_location,
				news_link:a.news_link,
				news_link_2:a.news_link_2,
				perpetratorHeight:a.perpetratorHeight,
				perpetratorWeight:a.perpetratorWeight,
				perpetratorHairColor:a.perpetratorHairColor,
				perpetratorFeatures:a.perpetratorFeatures,
				image:a.image,
				acceptedToSend:a.acceptedToSend,
				acceptedToSendData:a.acceptedToSendData
			});

			this.mapData=a.mapData;
			this.freportUpdate.setControl('reportCat', this.fb.array(a.reportCat || []));
			this.freportUpdate.setControl('policeAnswer', this.fb.array(a.policeAnswer || []));
			// check for global number of category - home page box number
			this.checkCategory(a.reportCat);
			this.report.unsubscribe();
		});
	}

	private createForm() {
		//inicialization
		this.freportUpdate=this.fb.group({
			title:'',
			date:'',
			description:'',
			reportDate:['', (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)],
			time:'',
			reportCat:this.fb.array([]),
			youAre:'',
			happened:'',
			placeType:'',
			reportPolice:'',
			policeAttitude:'',
			policeAnswer:this.fb.array([]),
			published:'',
			place_location:'',
			news_link:'',
			news_link_2:'',
			image:'',
			perpetratorHeight:'',
			perpetratorAge:'',
			perpetratorWeight:'',
			perpetratorHairColor:'',
			perpetratorFeatures:'',	
			email:'',
			acceptedToSend:'',
			acceptedToSendData:''	
		});
	}

	are_you:any=[{id:1, name:"Сведок"},{id:2,name:"Жртва"},{id:3,name:"Друго"}];
	it_happened:any=[{id:1, name:"Повеќе од 5 пати"},{id:2, name:"2 до 5 пати"},{id:3,name:"Еднаш"}];
	place_type:any=[{id:1,name:"Улица или паркинг"},{id:2, name:'Пазар'},{id:3,name:"Јавен превоз или додека сте чекале"},{id:4,name:'Продавница'},{id:5,name:'Парк'},{id:6,name:'Кола'},{id:7,name:'Работа'},{id:8,name:'Диско'},{id:9,name:'Образовна установа'},{id:10,name:'Друга јавна зграда'},{id:11,name:'Не се сеќавам'},{id:12,name:'Без одговор'}];
	report_police:any=[{id:1,name:'Да'},{id:2,name:'Не'},{id:3,name:'Без одговор'}];
	police_attitude:any=[{id:1, name:"Сосема незадоволна"},
{id:2, name:"Донекаде незадоволна"},
{id:3, name:"Ниту задоволна, ниту незадоволна"},
{id:4, name:"Донекаде задоволна"},
{id:5, name:"Сосема задоволна"},
{id:6,name:"Не знам"},{id:7,name:'Без одговор'}];
	police_answer:any=[{name:"Ме обвинија мене за инцидентот", ischecked:false},
		{name:"Не направија ништо", ischecked:false},
		{name:"Го истражија инцидентот",ischecked:false},
		{name:"Го минимизираа/тривијализираа инцидентот",ischecked:false},
		{name:"Го запишаа инцидентот", ischecked:false},
		{name:"Го фатија сторителот", ischecked:false},
		{ischecked:false,name:'Без одговор'},
		{ischecked:false,name:'Друго'}]
// select checkbox Categories
	
	get f() { return this.freportUpdate.controls;}


	async checkCategory(newCatArray){
		var makeArrayMainCat=new Array();
		this.countCat=await this.reportService.getCountData();	
		for(let i=0;i<this.countCat.length;i++){
			for (let j=0;j<this.countCat[i].length;j++){
				makeArrayMainCat.push(this.countCat[i][j]);
			}
		}
		//create new globalMainCat
		for(let key in newCatArray){
			
			let count=makeArrayMainCat[key].count;
			if(newCatArray[key].ischecked===true){
				this.globalMainCat.push({name:newCatArray[key].name,count:count+1});
			}else{
				this.globalMainCat.push({name:newCatArray[key].name,count:count});
			}
		}
		console.log(this.globalMainCat);
	}


	onCheckPlublished(val){
		console.log(val);
		this.published=val;
	}

	uploadImage(event, name) {
		const file = event.target.files[0];
		const filePath = 'reportImages/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const task=this.uploadImageService.uploadImage(filePath, file);
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available
		task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					console.log(downloadURL);
					this.downloadURL=downloadURL;
					this.f.image.patchValue(this.downloadURL);
				});
			})
			).subscribe();
		

	}


	isCategorySelected(event,category){
		var array=this.freportUpdate.get('reportCat')as FormArray;
		if(event.target.checked){
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}


	isPoliceAnswerSelected(event,answer){
		var arraypolice=this.freportUpdate.get('policeAnswer')as FormArray;
		if(event.target.checked){
			arraypolice.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraypolice.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			arraypolice.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraypolice.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}

	sendMail(title,date,description,place_location,youAre,happened,placeType,reportPolice,policeAttitude,acceptedToSendEmail) {
		 this.emailService.sendMessage(title,date,description,place_location,youAre,happened,placeType,reportPolice,policeAttitude, acceptedToSendEmail);
		 

		 //for test//
		// const obj = {title: title,date:date, description:description, youAre:youAre,happened:happened,place_location:place_location,
		// placeType:placeType,reportPolice:reportPolice,policeAttitude:policeAttitude,email:'risteskaa@gmail.com'};
		// const myJSON = JSON.stringify(obj);
		// var b=this.http.post("assets/send_email.php",myJSON,{responseType: 'text'}).subscribe(a=>{
		// 		console.log(a);
		// 	});
		// 	console.log(b);
		}

		deleteReport(){
		this.reportService.delete(this.id,this.title);
		this.route.navigate(['dashboard']);
		}


		onSubmit({value}) {
			this.submitted = true;
			//console.log(value);
			if (value.published==false) {
				this.published=false;
			return;
		}
		console.log(value.acceptedToSendEmail)

			if(value.acceptedToSend==true){
				this.sendMail(value.title,value.reportDate,value.description,value.place_location, 
					value.youAre, value.happened, value.placeType, value.reportPolice,value.policeAttitude, value.acceptedToSendEmail);
			}

			if(value.published==true){
				value["mapData"] = this.mapData;

			}
			this.reportService.countUpdate({reportCat:this.globalMainCat});

			this.reportService.createPublished(value).then((value) => {
				console.log('Created new item successfully!');
				this.route.navigate(['dashboard']);
			});
			this.reportService.delete(this.id,value.name);
		}

		

	}
