<div class="container-fluid loginHeader">
	<div class="container">
			<nav class="navbar navbar-expand-lg navbar-light">
				<a class="navbar-brand logo" href="/dashboard">
					<img src="assets/img/logo.png">
				</a>
				<div style="position: absolute;right: 0; font-size: 20px"><a class="nav-link" (click)="signOut()"> oдјави се: <fa-icon [icon]="faSignOutAlt"></fa-icon></a>
						</div>
				
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarNavAltMarkup" style="text-transform: uppercase;">
					<div class="navbar-nav nav">
						<a class="nav-link" href="/dashboard-add-report">{{"Пријави нов инцидент"|translate}}</a>
						<a class="nav-link" href="/dashboard-publication-list">{{"Студии"|translate}}</a>
						<a class="nav-link" href="/dashboard-page/important-contacts">{{"Важни контакти"|translate}}</a>

						<a class="nav-link" href="/dashboard-page/dictionary">{{"Поимник"|translate}}</a>
						<a class="nav-link" href="dashboard-page/about-project">{{"За проектот"|translate}}</a>
						<a class="nav-link" href="/dashboard-blog-list">{{"Блог"|translate}}</a>
						<a class="nav-link" href="/dashboard-page/term_of_policy">{{"Политика"|translate}}</a>
						<a class="nav-link" href="/dashboard-page/contact">{{"Контакт"|translate}}</a>
					</div>
				</div>
				<!-- <div class="lang" style="position: absolute;bottom:0; right:0">
					<span class="form-inline">
						<select class="form-control language" #selectedLang (change)="switchLang(selectedLang.value)">
							<option *ngFor="let language of translate.getLangs()" [value]="language"
							[selected]="language === translate.currentLang">
							{{ language }}
						</option>
					</select>
				</span>
			</div> -->
		</nav>
	</div>
</div>
