import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import { ReportService } from 'src/app/shared/services/report.service';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import {UploadImageService} from 'src/app/shared/services/upload-image.service'; 
import { finalize } from 'rxjs/operators';
@Component({
	selector: 'app-dashboard-reports-published-update',
	templateUrl: './dashboard-reports-published-update.component.html',
	styleUrls: ['./dashboard-reports-published-update.component.scss']
})
export class DashboardReportsPublishedUpdateComponent implements OnInit {
	id:string;
	report;
	published;
	uploadPercent;
	downloadURL;
	submitted=false;
	freportUpdate:FormGroup;
	title;
	mapData;
	acceptedToSend;
	constructor(
		public route:Router,
		private rout:ActivatedRoute,
		private reportService:ReportService,
		private fb: FormBuilder,private myStorage: AngularFireStorage,
		private uploadImageService:UploadImageService,
		) {
	}

	ngOnInit(){
		this.createForm();
		this.id = this.rout.snapshot.paramMap.get('id');
		this.report=this.reportService.publishedReportGetData(this.id).valueChanges().subscribe(a=>{
			this.freportUpdate.patchValue({
				title:a.title,
				date:a.date,
				time:a.time,
				description:a.description,
				reportDate:a.reportDate,
				youAre:a.youAre,
				happened:a.happened,
				placeType:a.placeType,
				reportPolice:a.reportPolice,
				policeAttitude:a.policeAttitude,
				published:a.published,
				place_location:a.place_location,
				news_link:a.news_link,
				news_link_2:a.news_link_2,
				perpetratorHeight:a.perpetratorHeight,
				perpetratorAge:a.perpetratorAge,
				perpetratorWeight:a.perpetratorWeight,
				perpetratorHairColor:a.perpetratorHairColor,
				perpetratorFeatures:a.perpetratorFeatures,
				image:a.image,
				acceptedToSend:a.acceptedToSend,
				acceptedToSendData:a.acceptedToSendData


			});


			this.mapData=a.mapData;
			this.freportUpdate.setControl('reportCat', this.fb.array(a.reportCat || []));
			this.freportUpdate.setControl('policeAnswer', this.fb.array(a.policeAnswer || []));
			this.report.unsubscribe();
		});
	}

	private createForm() {
		//inicialization
		this.freportUpdate=this.fb.group({
			title:'',
			date:'',
			description:'',
			reportDate:['', (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)],
			time:'',
			reportCat:this.fb.array([]),
			youAre:'',
			happened:'',
			placeType:'',
			reportPolice:'',
			policeAttitude:'',
			policeAnswer:this.fb.array([]),
			published:'',
			place_location:'',
			news_link:'',
			news_link_2:'',
			image:'',
			perpetratorHeight:'',
			perpetratorAge:'',

			perpetratorWeight:'',
			perpetratorHairColor:'',
			perpetratorFeatures:'',	
			
			
			
		});
	}

	are_you:any=[{id:1, name:"Сведок"},{id:2,name:"Жртва"}];
	it_happened:any=[{id:1, name:"Повеќе од 5 пати"},{id:2, name:"2 до 5"},{id:3,name:"Еднаш"}];
	place_type:any=[{id:1,name:"Улица или паркинг"},{id:2, name:'Пазар'},{id:3,name:"Јавен превоз или додека сте чекале"},{id:4,name:'Продавница'},{id:5,name:'Парк'},{id:6,name:'Кола'},{id:7,name:'Работа'},{id:8,name:'Диско'},{id:9,name:'Образовна установа'},{id:10,name:'Друга јавна зграда'},{id:11,name:'Не се сеќавам'},{id:12,name:'Без одговор'}];
	report_police:any=[{id:1,name:'Да'},{id:2,name:'Не'},{id:3,name:'Без одговор'}];
	police_attitude:any=[{id:1, name:"Многу задоволни"},{id:2,name:"Задоволни"},{id:3,name:"Незадоволни"},{id:4,name:"Многу незадоволни"},{id:5,name:"Не знам"},{id:6,name:"Без одговор"}];
	
	get f() { return this.freportUpdate.controls; }


	onCheckPlublished(val){
		this.published=val;
	}

	uploadImage(event, name) {
		const file = event.target.files[0];
		const filePath = 'reportImages/'+name.value;
		const fileRef = this.myStorage.ref(filePath);
		const task=this.uploadImageService.uploadImage(filePath, file);
		this.uploadPercent = task.percentageChanges();
		// get notified when the download URL is available
		task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(downloadURL => {
					console.log(downloadURL);
					this.downloadURL=downloadURL;
					this.f.image.patchValue(this.downloadURL);
				});
			})
			).subscribe();
		

	}


	isCategorySelected(event,category){
		var array=this.freportUpdate.get('reportCat')as FormArray;
		if(event.target.checked){
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			array.controls.forEach(function (element,index) {
				if(element.value.name==category){
					array.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}


	isPoliceAnswerSelected(event,answer){
		var arraypolice=this.freportUpdate.get('policeAnswer')as FormArray;
		if(event.target.checked){
			arraypolice.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraypolice.at(index).patchValue({name:element.value.name,ischecked:true});
				}
			})
		}else{
			arraypolice.controls.forEach(function (element,index) {
				if(element.value.name==answer){
					arraypolice.at(index).patchValue({name:element.value.name,ischecked:false});
				}
			})
		}
	}
	deleteReport(){
			this.reportService.deletePublished(this.id,this.title);
			this.route.navigate(['dashboard']);
		}

	onSubmit({value}) {
		this.submitted = true;
		if(value.published==true){
			value["mapData"] = this.mapData;
			this.reportService.update(this.id,value).then((value) => {
				console.log('Update item successfully!');
				this.route.navigate(['dashboard-published-reports']);
			});
			this.reportService.deletePublished(this.id,value.name);
		}
		
	}


}
